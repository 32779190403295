import React, { useState, useRef } from 'react';
import { Layout, Card, Input, Button, Dropdown, Menu, Modal, message, Spin, Tooltip } from 'antd';
// import { FiAlignLeft, FiAlignCenter, FiAlignRight, FiBold } from 'react-icons/fi';
import { DownOutlined, LinkOutlined, DeleteOutlined } from '@ant-design/icons';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import {
  GQL_updatecourseWorkData,
  GQL_courseWorkData,
  CourseWorkMaterialState,
  CourseWorkType,
  Date1,
  TimeOfDay1,
  GQL_GoogleCourseWork,
  AssigneeMode
} from '../../types/googleClassroom';
import moment from 'moment';
import UploadInput from '../../shared/UploadInput';
import CoreSettingsSider from './CoreSettingsSider';
import { RcFile } from 'antd/lib/upload';
import Editor from '../../shared/Editor';
import { RemirrorProvider } from 'remirror/react';

const { Content } = Layout;

const GoogleAssignmentCorePage = () => {
  const { courseId, courseWorkId } = useParams<{ courseId: string; courseWorkId: string }>();
  const [assignmentName, setAssignmentName] = useState<string>('');
  const [assignmentDescription, setAssignmentDescription] = useState<string>('');
  const [assignmentType, setAssignmentType] = useState<CourseWorkType | undefined>();
  const [maxPoints, setMaxPoints] = useState<number | undefined>();
  const [dueDate, setDueDate] = useState<moment.Moment | null>(null);
  const [dueTime, setDueTime] = useState<moment.Moment | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isFormModalVisible, setIsFormModalVisible] = useState<boolean>(false); // State for form link modal
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [links, setLinks] = useState<string[]>([]);
  const [formLinks, setFormLinks] = useState<string[]>([]);// State for form links
  const [assigneeMode, setAssigneeMode] = useState<AssigneeMode>(AssigneeMode.ALL_STUDENTS);
  const [individualStudents, setIndividualStudents] = useState<string[] | undefined>(undefined); // Adjust type
  const fileInputRef = useRef<HTMLInputElement>(null);
  const originalValues = useRef({
    assignmentName: '',
    assignmentDescription: '',
    assignmentType: undefined,
    maxPoints: undefined,
    dueDate: null,
    dueTime: null,
    assigneeMode: AssigneeMode.ALL_STUDENTS,
    formLinks,
  });

  const [fileUploading, setFileUploading] = useState<boolean>(false);
  console.log(fileUploading);
  const { loading: courseWorkLoading, refetch } = useQuery<GQL_GoogleCourseWork, { courseId: string; id: string }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkById,
    {
      variables: { courseId, id: courseWorkId },
      skip: !courseId || !courseWorkId,
      onCompleted: (data) => {
        const courseWork = data.getCourseWorkById;
        setAssignmentName(courseWork.title || '');
        setAssignmentDescription(courseWork.description || '');
        setAssignmentType(courseWork.workType as CourseWorkType);
        setMaxPoints(courseWork.maxPoints);
        setAssigneeMode(courseWork.assigneeMode as AssigneeMode);
        if (courseWork.assigneeMode === AssigneeMode.INDIVIDUAL_STUDENTS) {
          setIndividualStudents(courseWork.individualStudentsOptions?.studentIds);
        } else {
          setIndividualStudents([])
        }
        if (courseWork.dueDate) {
          setDueDate(moment({ year: courseWork.dueDate.year, month: courseWork.dueDate.month - 1, day: courseWork.dueDate.day }));
        }
        if (courseWork.dueTime) {
          setDueTime(moment({ hour: courseWork.dueTime.hours, minute: courseWork.dueTime.minutes, second: courseWork.dueTime.seconds }));
        }
        const links: string[] = [];
        const formLinks: string[] = [];

        courseWork.materials?.forEach(material => {
          if (material.link) {
            links.push(material.link.url);
          }
          if (material.form) {
            formLinks.push(material.form.formUrl);
          }
        });

        setLinks(links);
        setFormLinks(links);
        originalValues.current.formLinks = formLinks;
        setIsEditMode(true);
      },
    }
  );

  const [updateAssignmentMutation] = useMutation<GQL_updatecourseWorkData>(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.UpdateCourseWork,
    {
      onCompleted: (data) => {
        console.log('Assignment Updated!', data);
        setLoading(false);
        refetch();
      },
      onError: (error) => {
        console.error('Error updating assignment:', error);
        setLoading(false);
      },
    }
  );

  const [createAssignmentMutation] = useMutation<GQL_GoogleCourseWork>(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.CreateCourseWork,
    {
      onCompleted: (data) => {
        const courseWorkId = data?.createCourseWork?.id;
        history.push(`/googleclassroom-teacher-dashboard/class/${courseId}/assignment-summary/${courseWorkId}`);
        console.log('Assignment Created!', data);
        setLoading(false);
        if (courseWorkId) {
          refetch();
        }
      },
      onError: (error) => {
        console.error('Error creating assignment:', error);
        setLoading(false);
      },
    }
  );
  const [uploadFileMutation] = useMutation(gqlSchema.GoogleClassroomSchema.mutations.GCLASS.UploadFile);

  const history = useHistory();

  const generateUpdateMask = () => {
    const updateMaskFields = [];
    if (assignmentName !== originalValues.current.assignmentName) updateMaskFields.push('title');
    if (assignmentDescription !== originalValues.current.assignmentDescription) updateMaskFields.push('description');
    if (maxPoints !== originalValues.current.maxPoints) updateMaskFields.push('maxPoints');
    if (dueDate && !dueDate.isSame(originalValues.current.dueDate)) updateMaskFields.push('dueDate');
    if (dueTime && !dueTime.isSame(originalValues.current.dueTime)) updateMaskFields.push('dueTime');
    // if (formLinks.join(',') !== originalValues.current.formLinks.join(',')) updateMaskFields.push('materials');

    return updateMaskFields.join(',');
  };


  const handleSaveAssignment = () => {
    setLoading(true);
  
    // Check for required fields
    if (!assignmentName || !assignmentDescription || !maxPoints || !dueDate || !dueTime) {
      message.error('Please fill all the required fields.');
      setLoading(false);
      return;
    }

    const now = moment();
  if (dueDate?.isSame(now, 'day') && dueTime?.isBefore(now, 'minute')) {
    message.error('Past time is not allowed.');
    setLoading(false);
    return;
  }
  
    const formattedDueDate: Date1 = dueDate
      ? {
        year: dueDate.year(),
        month: dueDate.month() + 1,
        day: dueDate.date(),
      }
      : { year: 0, month: 0, day: 0 };
  
    const formattedDueTime: TimeOfDay1 = dueTime
      ? {
        hours: dueTime.hours(),
        minutes: dueTime.minutes(),
        seconds: dueTime.seconds(),
        nanos: 0,
      }
      : { hours: 0, minutes: 0, seconds: 0, nanos: 0 };
  
    if (assigneeMode === AssigneeMode.INDIVIDUAL_STUDENTS && !individualStudents?.length) {
      message.error('Please select individual students or choose "All Students".');
      setLoading(false);
      return;
    }
  
    const newAssignment: GQL_courseWorkData = {
      title: assignmentName || '',
      description: assignmentDescription || '',
      state: CourseWorkMaterialState.PUBLISHED,
      maxPoints: maxPoints || 0,
      dueDate: formattedDueDate,
      dueTime: formattedDueTime,
      materials: formLinks.map((link: string) => {
        return ({
          link: {
            url: link
          }
        });
      }),
      assigneeMode,
      individualStudentsOptions:
        !isEditMode
          ? assigneeMode === AssigneeMode.INDIVIDUAL_STUDENTS && individualStudents
            ? { studentIds: individualStudents }
            : undefined
          : undefined,
    };
  
    const updateMask = generateUpdateMask();
  
    if (courseWorkId) {
      updateAssignmentMutation({
        variables: {
          courseId: courseId,
          id: courseWorkId,
          data: newAssignment,
          updateMask,
        },
      })
        .then(() => {
          message.success('Assignment updated successfully!');
          history.push(`/googleclassroom-teacher-dashboard/class/${courseId}/assignment-summary/${courseWorkId}`);
        })
        .catch((error) => {
          console.error('Error updating assignment:', error);
          setLoading(false);
        });
    } else {
      newAssignment.workType = assignmentType || CourseWorkType.ASSIGNMENT;
      createAssignmentMutation({
        variables: {
          courseId: courseId,
          data: newAssignment,
        },
      })
        .then((response) => {
          if (response?.data?.createCourseWork?.id) {
            message.success('Assignment created successfully!');
          } else {
            message.error('Failed to create assignment.');
          }
        })
        .catch((error) => {
          console.error('Error creating assignment:', error);
          message.error('Failed to create assignment.');
          setLoading(false);
        });
    }
  };  

  // const handleAlignment = (alignment: 'left' | 'center' | 'right') => {
  //   setAssignmentDescription((prevDescription) => {
  //     let newDescription = prevDescription.replace(/style="text-align: (left|center|right);"/g, '');
  //     newDescription = `<div style="text-align: ${alignment};">${newDescription}</div>`;
  //     return newDescription;
  //   });
  // };

  // const toggleBold = () => {
  //   setAssignmentDescription((prevDescription) => {
  //     if (prevDescription.startsWith('<b>') && prevDescription.endsWith('</b>')) {
  //       return prevDescription.slice(3, -4);
  //     } else {
  //       return `<b>${prevDescription}</b>`;
  //     }
  //   });
  // };

  const handleMenuClick = ({ key }: { key: React.Key }) => {
    if (key.toString() === '1') { // Open form link modal
      setIsFormModalVisible(true);
    } else if (key.toString() === '2' && fileInputRef.current) {
      fileInputRef.current.click(); // Click the file input programmatically for 'Upload' option
    }
  };

  const handleFormLinkSave = () => {
    setFormLinks([...formLinks, linkUrl]);
    setIsFormModalVisible(false);
    setLinkUrl('');
  };

  const handleFileUpload = async (payload: { file: File }) => {
    setFileUploading(true);
    try {
      console.log('Uploading file:', payload.file);

      const response = await uploadFileMutation({
        variables: {
          file: [payload.file],
          courseId
        },
      });

      if (response.data) {
        setFormLinks((prevLinks) => [...prevLinks, ...response.data.uploadFilesForGoogleClassroom]);
        console.log('response: ', response.data);
        message.success(`${payload.file.name} uploaded successfully`);
      } else {
        message.error(`Failed to upload ${payload.file.name}`);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error(`Error uploading ${payload.file.name}`);
    } finally {
      setFileUploading(false); // End file upload loader
    }
  };


  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon>
        <Button style={{ border: '2px solid #d9d9d9', borderRadius: '4px', width: '100%' }}>
          <LinkOutlined style={{ marginRight: '1px' }} />
          Link
        </Button>
      </Menu.Item>
      <Menu.Item key="2" icon>
        <UploadInput
          title="Upload File"
          showClear={false}
          onClear={() => {
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          }}
          customRequest={(options) => {
            const file = options.file as RcFile;
            handleFileUpload({ file });
            return true;
          }}
        />
      </Menu.Item>
    </Menu>
  );

  // Function to remove a form link from state
  const handleRemoveFormLink = (index: number) => {
    const updatedFormLinks = [...formLinks];
    updatedFormLinks.splice(index, 1);
    setFormLinks(updatedFormLinks);

    // If in edit mode, update the original values as well
    if (isEditMode) {
      originalValues.current.formLinks = updatedFormLinks;
    }

  };

  const handleEditorChange = (event: any) => {
    const newValue = event?.value || '';
    setAssignmentDescription(newValue);
  };


  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Spin spinning={fileUploading} tip="Uploading file..." style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <div style={{ flex: 1 }}>
            <Card style={{ height: '100%' }}>
              <CoreSettingsSider
                courseId={courseId}
                courseWorkId={courseWorkId}
                assignmentName={assignmentName}
                assignmentDescription={assignmentDescription}
                assignmentType={assignmentType}
                maxPoints={maxPoints}
                dueDate={dueDate}
                dueTime={dueTime}
                onSaveAssignment={handleSaveAssignment}
                setAssignmentType={setAssignmentType}
                setMaxPoints={setMaxPoints}
                setDueDate={setDueDate}
                setDueTime={setDueTime}
                loading={loading || courseWorkLoading}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                links={links} // Pass state to child component
                formLinks={formLinks} // Pass state to child component
                setLinks={setLinks} // Pass state updater to child component
                setFormLinks={setFormLinks} // Pass state updater to child component
                assigneeMode={assigneeMode}
                setAssigneeMode={setAssigneeMode}
                individualStudents={individualStudents}
                setIndividualStudents={setIndividualStudents}

              />
            </Card>
          </div>
          <div style={{ flex: 3, padding: '0 24px' }}>
            <Card style={{ height: '100%' }}>
              <Content style={{ padding: '24px' }}>
                <h2>Assignment Name</h2>
                <Input
                  placeholder="Enter Assignment Name"
                  value={assignmentName}
                  onChange={(e) => setAssignmentName(e.target.value)}
                  style={{ borderRadius: '4px' }}
                />
                <br />
                <br />
                <h2>Description</h2>
                <div dangerouslySetInnerHTML={{ __html: assignmentDescription }} />
                <br />
                <RemirrorProvider>
                  <Editor
                    value={assignmentDescription}
                    onChange={handleEditorChange}
                    placeholder="Enter Assignment Description"
                    showAlign={true}
                  />
                </RemirrorProvider>

                <br />
                <br />
                <Tooltip title={isEditMode ? "Cannot access in edit mode" : ""}>
                  <Dropdown overlay={menu} trigger={['click']} disabled={isEditMode}>
                    <Button>
                      Attach File <DownOutlined />
                    </Button>
                  </Dropdown>
                </Tooltip>
                <br />
                <br />
                <ul>
                  {formLinks.map((formLink, index) => (
                    <li key={index}>
                      <a href={formLink} target="_blank" rel="noopener noreferrer">{formLink}</a>
                      <Button type="text" icon={<DeleteOutlined />} onClick={() => handleRemoveFormLink(index)} />
                    </li>
                  ))}
                </ul>
              </Content>
            </Card>
          </div>
        </div>
        <Modal
          title="Add Form Link"
          visible={isFormModalVisible}
          onOk={handleFormLinkSave}
          onCancel={() => setIsFormModalVisible(false)}
        >
          <Input
            placeholder="Form Link URL"
            onChange={(e) => setLinkUrl(e.target.value)}
          />
        </Modal>
      </Spin>
    </Layout>
  );
};

export default withRouter(GoogleAssignmentCorePage);
