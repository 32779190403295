import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col, Badge, Spin, message, Tooltip } from 'antd';
import { BsClock } from 'react-icons/bs';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as S from './styles';
import { themeConfig } from '../../../../utils/theme';
import AssignmentSummaryPopup from '../../../GoogleClassroomStudentsDashboardPage/GActiveAssignmentsRow/AssignmentSummaryPopup';
import { gqlSchema } from '../../../../gql/schema';

interface Props {
  timeLeft: number;
  assignmentName: string;
  assignmentId: string;
  timeWarning: boolean;
  icon: React.ReactNode | null;
  description: string;
  classId: string;
  progress: number;
  type: 'Assignment' | 'Design Challenge';
  version?: number;
  courseWorkType: string;
  courseName: string;
  className: string;
}

const Item: React.FC<Props> = (props) => {
  const {
    assignmentName,
    assignmentId,
    icon,
    classId,
    courseWorkType,
    className,
  } = props;

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [timeLeftState, setTimeLeftState] = useState<string>('');

  const { data: courseWorkListData } = useQuery(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkList,
    {
      variables: { courseId: classId, data: "PUBLISHED" },
      onError: (err) => {
        message.error('Error fetching coursework list data: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const [fetchCourseWork, { data: courseWorkData, error: courseWorkError, loading: courseWorkLoading }] = useLazyQuery(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkById,
    {
      onError: (err) => {
        message.error('Error fetching assignment data: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  useEffect(() => {
    const calculateTimeLeft = () => {
      const assignment = courseWorkListData?.getCourseWorkList.find((cw: any) => cw.id === assignmentId);
      if (assignment?.dueDate) {
        const dueDate = new Date(assignment.dueDate.year, assignment.dueDate.month - 1, assignment.dueDate.day).getTime();
        const currentTime = Date.now();
        const timeLeftValue = dueDate - currentTime;

        if (timeLeftValue <= 0) {
          setTimeLeftState('Finished');
        } else {
          const seconds = Math.floor((timeLeftValue / 1000) % 60);
          const minutes = Math.floor((timeLeftValue / (1000 * 60)) % 60);
          const hours = Math.floor((timeLeftValue / (1000 * 60 * 60)) % 24);
          const days = Math.floor(timeLeftValue / (1000 * 60 * 60 * 24));

          if (days > 0) {
            setTimeLeftState(`${days} day${days > 1 ? 's' : ''}`);
          } else if (hours > 0) {
            setTimeLeftState(`${hours} hour${hours > 1 ? 's' : ''}`);
          } else if (minutes > 0) {
            setTimeLeftState(`${minutes} minute${minutes > 1 ? 's' : ''}`);
          } else {
            setTimeLeftState(`${seconds} second${seconds !== 1 ? 's' : ''}`);
          }
        }
      } else {
        setTimeLeftState('');
      }
    };

    calculateTimeLeft();
  }, [assignmentId, courseWorkListData]);

  const handleResumeClick = useCallback(() => {
    fetchCourseWork({ variables: { courseId: classId, id: assignmentId } });
    setPopupVisible(true);
  }, [fetchCourseWork, classId, assignmentId]);

  const handleClosePopup = useCallback(() => {
    setPopupVisible(false);
  }, []);

  const truncateDescription = (text: string | null | undefined, maxLength: number) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text || '';
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const assignment = courseWorkListData?.getCourseWorkList.find((cw: any) => cw.id === assignmentId);
  const assignmentDescription = assignment?.description;

  return (
    <S.Card>
      <Row align="middle">
        <Col span={5} style={{ textAlign: 'left' }}>
          <S.IconWrapper>{icon}</S.IconWrapper>
        </Col>
        <Col span={14}>
          <S.Title $align="left">{className}</S.Title>
        </Col>
        <Col span={24} style={{ textAlign: 'left', marginTop: '8px' }}>
        <Badge
            style={{ backgroundColor: '#E9A643', borderRadius: '40px', color: '#fff', fontWeight: 500, fontSize: '12px', padding: '8px 16px', textAlign: 'center',display: 'inline-block'  }}
            count={<div style={{ textAlign: 'center' }}>{capitalizeFirstLetter(courseWorkType)}</div>}
          />
        </Col>
        <Col span={24} style={{ marginTop: '8px' }}>
          <S.AssignmentName>
            <span style={{
              fontWeight: 500, // Bold for title appearance
              fontSize: '14px', // Slightly larger font size for title
              color: '#333', // Dark color for readability
              margin: '0', // No extra margin
              display: 'block', // Display as block for full width
              textAlign: 'left', // Align text to the left
              textTransform: 'capitalize', // Capitalize first letter of each word
            }}>
              {assignmentName}
            </span>
          </S.AssignmentName>
        </Col>
        <Col span={24}>
        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: assignmentDescription }} />}>
            <S.Description dangerouslySetInnerHTML={{ __html: truncateDescription(assignmentDescription, 50) }}></S.Description>
          </Tooltip>
        </Col>
        {/* <Col span={5} style={{ textAlign: 'left' }}>
          <Badge count={capitalizeFirstLetter(courseWorkType)} style={{ backgroundColor: '#EAAC3533', borderRadius: '16px', color: '#000', fontWeight: 'bold' }} />
        </Col> */}
        {timeLeftState && (
          <Col span={24} style={{ textAlign: 'left', marginTop: '8px' }}>
            <Row align="middle">
              <BsClock style={{ minWidth: 18, minHeight: 18, marginRight: 8 }} />
              <S.TimeLeft $fontSize="0.8em" $warning={timeLeftState === 'Finished'}>
                {timeLeftState}
              </S.TimeLeft>
            </Row>
          </Col>
        )}
      </Row>
      <Row style={assignmentDescription ? { marginTop: '1em' } : { marginTop: '3em' }} gutter={16}>
        <Col span={16}>
          <S.DashboardButton
            theme={themeConfig.primaryOutlined}
            text={'Resume Assignment'}
            htmlType="button"
            onClick={handleResumeClick}
            block
          />
        </Col>
      </Row>
      {isPopupVisible && (
        <Spin spinning={courseWorkLoading}>
          <AssignmentSummaryPopup
            courseId={classId}
            assignmentId={assignmentId}
            visible={isPopupVisible}
            onClose={handleClosePopup}
            isFinished={false}
            courseWorkData={courseWorkData}
            courseWorkError={courseWorkError}
            courseWorkLoading={courseWorkLoading}
          />
        </Spin>
      )}
    </S.Card>
  );
};

export default Item;
