import React, { useCallback, useEffect } from 'react';
import { Form as AntdForm, Row, message, Typography, Col, Form } from 'antd';

import Input from '../../shared/Input';
import * as S from './styles';
import Button from '../../shared/Button';
import { ILoginProps } from '../../types';
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_LoginResponse } from '../../types/login';
import { useAuth } from '../../hooks/useAuth';
import client from '../../gql/api';
import cleverC from '../../assets/cleverC.png';
import googleClassroom from '../../assets/google-classroom.svg';
import classLinkLogoSrc from '../../assets/classlink.png';
import googleLogoSrc from '../../assets/google.png';
import adiLearningHubSrc from '../../assets/adi-learning-hub.svg';
import useGoogleRoleUpdater from '../../hooks/updatePreferredRoleToGoogleStudent';



type Props = RouteComponentProps<null, any, any>;

const LoginPage = (props: Props) => {
  const history = useHistory();
  const { setSignedIn } = useAuth();
  const queryStringParams = props.location.search;
  const queryStringParamsObj = new URLSearchParams(queryStringParams);
  const code = queryStringParamsObj.get('code');
  const provider = queryStringParamsObj.get('provider');
  const { updatePreferredRoleToGoogleTeacher, updatePreferredRoleToGoogleStudent, updatePreferredRoleTeacher } = useGoogleRoleUpdater();



  const [submitLoginWithClever, { loading: loadingCleverSSO }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithClever,
    {
      onCompleted: ({ loginWithClever }: { loginWithClever: GQL_LoginResponse }) => {
        const { accessToken, ...data } = loginWithClever;

        setSignedIn({
          token: loginWithClever.accessToken,
          user: { ...data, id: loginWithClever.id },
        });
        client.resetStore();
        console.log("dop", data.roles);

        if (data.roles.includes('student')) history.push('/student-dashboard');
        else history.push('/teacher-dashboard');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitLoginWithClassLink, { loading: loadingClassLinkSSO }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithClasslink,
    {
      onCompleted: ({ loginWithClasslink }: { loginWithClasslink: GQL_LoginResponse }) => {
        const { accessToken, ...data } = loginWithClasslink;

        setSignedIn({
          token: loginWithClasslink.accessToken,
          user: { ...data, id: loginWithClasslink.id },
        });
        client.resetStore();

        if (data.roles.includes('student')) history.push('/student-dashboard');
        else history.push('/teacher-dashboard');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitLoginWithGoogle, { loading: loadingGoogleSSO }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithGoogle,
    {
      onCompleted: ({ loginWithGoogle }: { loginWithGoogle: GQL_LoginResponse }) => {
        const { accessToken, ...data } = loginWithGoogle;

        setSignedIn({
          token: loginWithGoogle.accessToken,
          user: { ...data, id: loginWithGoogle.id },
        });
        client.resetStore();

        if (data.roles.includes('teacher')) {
          updatePreferredRoleTeacher()
        }
        else {
         history.push('/student-dashboard');
        }


      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );
  const [submitLoginWithGoogleTest, { loading: loadingGoogleSSOTest }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithGoogleTest,
    {
      onCompleted: ({ loginWithGoogleTest }: { loginWithGoogleTest: GQL_LoginResponse }) => {
        const { accessToken, googleAuthToken, googleAuthRefreshToken, ...data } = loginWithGoogleTest;

        console.log("token", googleAuthToken);

        setSignedIn({
          token: accessToken,
          user: { ...data, googleAuthToken, googleAuthRefreshToken },
        });

        if (loginWithGoogleTest.isGoogleTeacher) {
          updatePreferredRoleToGoogleTeacher();
          history.push('/googleclassroom-teacher-dashboard');
        }
        else {
          updatePreferredRoleToGoogleStudent();
          history.push('/googleclassroom-students-dashboard');
        }

        // if (data.roles.includes('student')) history.push('/student-dashboard');
        // else history.push('/teacher-dashboard');
        client.resetStore();
      },
      onError: (error) => {
        message.error(error.message);
      },
    }
  );

  useEffect(() => {
    if (code) {
      if (provider === 'clever') {
        submitLoginWithClever({
          variables: {
            code,
          },
        });
      } else if (provider === 'googleTest') {
        submitLoginWithGoogleTest({
          variables: {
            code,
          },
        });
      } else if (provider === 'classlink') {
        submitLoginWithClassLink({
          variables: {
            code,
          },
        });
      } else if (provider === 'google') {
        submitLoginWithGoogle({
          variables: {
            code,
          },
        });
      }
    }
  }, [code, provider, submitLoginWithClever, submitLoginWithClassLink, submitLoginWithGoogle, submitLoginWithGoogleTest]);

  const [submitLogin, { loading }] = useMutation(gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.login, {
    onCompleted: ({ login }: { login: GQL_LoginResponse }) => {
      const { accessToken, ...data } = login;

      setSignedIn({
        token: login.accessToken,
        user: { ...data, id: login.id },
      });

      client.resetStore();

      if (data.roles.includes('teacher')) history.push('/teacher-dashboard');
      else if (data.roles.includes('student')) history.push('/student-dashboard');
      else history.push('/teacher-dashboard');
    },
    onError: (error) => {
      console.error(error);
      message.error(<Typography.Text data-cy="components-loginpage-error-text">{error.message}</Typography.Text>);
    },
  });

  const onSubmitForm = useCallback(
    (values: ILoginProps) => {
      submitLogin({
        variables: {
          data: values,
        },
      });
    },
    [submitLogin],
  );

  const onLoginWithSSO = useCallback(({ provider }: { provider: 'clever' | 'classLink' | 'google' | 'googleTest' }) => {
    const redirect_uri = window.location.origin;
    const classLinkClientId =
      process.env.REACT_APP_CLASSLINK_CLIENT_ID || 'c16318123360510b4e4a607247e29ecc6256f09d2b90';
    const googleClientId =
      process.env.REACT_APP_GOOGLE_CLIENT_ID ||
      '1004692858571-ur6ao926rvd9pkgcm6v2c1q9uff8mhud.apps.googleusercontent.com';

    const scope = `https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.courses https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/classroom.rosters https://www.googleapis.com/auth/classroom.profile.emails https://www.googleapis.com/auth/classroom.profile.photos https://www.googleapis.com/auth/classroom.coursework.students.readonly https://www.googleapis.com/auth/classroom.coursework.me.readonly https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/classroom.coursework.me https://www.googleapis.com/auth/userinfo.email`;

    let url = '';
    if (provider === 'classLink') {
      url = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${redirect_uri}/sso?provider=classlink&client_id=${classLinkClientId}&response_type=code`;
    } else if (provider === 'clever') {
      url = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirect_uri}/sso?provider=clever&client_id=6fd9484b33eac85d533f`;
    } else if (provider === 'google') {
      url = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${googleClientId}&response_type=code&scope=email&redirect_uri=${redirect_uri}/sso?provider=google&service=lso&o2v=2&flowName=GeneralOAuthFlow`;
    } else if (provider === 'googleTest') {
      url = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${googleClientId}&response_type=code&scope=${scope}&redirect_uri=${redirect_uri}/sso?provider=googleTest&service=lso&o2v=2&flowName=GeneralOAuthFlow&access_type=offline&prompt=consent`;
    }
    const width = 600;
    const height = 800;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const windowFeatures = `scrollbars=yes,resizable=yes,toolbar=no,menubar=no,location=no,directories=no,status=no,top=${top},left=${left},width=${width},height=${height}`;
    window.open(url, 'authPopup', windowFeatures);
  }, []);

  const loadingSSO = loadingCleverSSO || loadingClassLinkSSO || loadingGoogleSSO || loadingGoogleSSOTest;

  return (
    <S.Container data-cy="components-loginpage-container">
      <S.RowSP justify="center" align="middle">
        <S.ColSP xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
          <img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" />
          <S.Title>Login To Your Account</S.Title>
          <S.RowForm gutter={16} align="middle">
            <Col span={10} xs={24} md={10} xl={10}>
              <Form onFinish={onSubmitForm} layout="vertical">
                <AntdForm.Item
                  label="Email"
                  name="username"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email address',
                    },
                  ]}
                >
                  <Input placeholder="Type your email here..." disabled={loadingSSO} />
                </AntdForm.Item>
                <AntdForm.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Password is required' }]}
                >
                  <Input
                    placeholder="Type your password here..."
                    type="password"
                    disabled={loadingSSO}
                  />
                </AntdForm.Item>
                <Row justify="end">
                  <Link data-cy="components-loginpage-forgotpassword-link" to="/forgot-password">
                    FORGOT PASSWORD?
                  </Link>
                </Row>
                <AntdForm.Item>
                  <Button
                    data-cy="components-loginpage-login-button"
                    text="Login"
                    htmlType="submit"
                    loading={loading || loadingSSO}
                    block
                    style={{
                      width: '50%',
                      margin: '0.5em auto 0', // Adjusted margin for less space between login button and "Or" container
                      textAlign: 'center',
                      display: 'block',
                    }}
                  />
                </AntdForm.Item>
              </Form>
            </Col>
            <S.OrContainer>
              <S.ColR>
                <S.OrText>Or</S.OrText>
              </S.ColR>
            </S.OrContainer>
            <S.ColSSO span={10} xs={24} lg={12} md={12} xl={12}>
              <S.RowSSO gutter={[16, 10]}>
                <Col span={24} xs={24} lg={24} md={24} xl={24}>
                  <S.GoogleSSOButton
                    data-cy="components-loginpage-button-loginwithgoogle"
                    onClick={() => onLoginWithSSO({ provider: 'google' })}
                  >
                    <img src={googleLogoSrc} alt="Google Logo" /> <S.SSOButtonDivider /> Continue with Google
                  </S.GoogleSSOButton>
                </Col>
                <Col span={24} xs={24} lg={24} md={24} xl={24}>
                  <S.CleverSSOButton
                    data-cy="components-loginpage-button-loginwithclever"
                    onClick={() => onLoginWithSSO({ provider: 'clever' })}
                  >
                    <img src={cleverC} alt="Clever Logo" /> <S.SSOButtonDivider /> Continue with Clever
                  </S.CleverSSOButton>
                </Col>
                <Col span={24} xs={24} lg={24} md={24} xl={24}>
                  <S.ClassLinkSSOButton
                    data-cy="components-loginpage-button-loginwithclasslink"
                    onClick={() => onLoginWithSSO({ provider: 'classLink' })}
                  >
                    <img src={classLinkLogoSrc} alt="ClassLink Logo" /> <S.SSOButtonDivider /> Continue with ClassLink
                  </S.ClassLinkSSOButton>
                </Col>
                <Col span={24} xs={36} lg={24} md={24} xl={24}>
                  <S.GoogleClassroomSSOButton
                    data-cy="components-loginpage-button-loginwithgoogleclassroom"
                    onClick={() => onLoginWithSSO({ provider: 'googleTest' })}
                  >
                    <img src={googleClassroom} alt="Google Classroom Logo" /> <S.SSOButtonDivider /> Login with Google Classroom
                  </S.GoogleClassroomSSOButton>
                </Col>
              </S.RowSSO>
            </S.ColSSO>
          </S.RowForm>
        </S.ColSP>
      </S.RowSP>
    </S.Container>
  );


};

export default withRouter(LoginPage);