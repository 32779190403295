import React, { useCallback } from 'react';
import { Row, Col, Badge, Tooltip } from 'antd';
import * as S from './styles';
import { themeConfig } from '../../../../utils/theme';
import { useHistory } from 'react-router-dom';
import { BsClock } from 'react-icons/bs';
import { formatDistance } from '../../../../utils/date';

interface Props {
  className: string;
  classColor: string;
  timeLeft: number | null;
  assignmentName: string;
  assignmentId: string;
  courseId: string;
  date?: string;
  timeWarning: boolean;
  icon: React.ReactElement;
  workType: string; // Added workType prop
  description: string; // Added description prop
}

const Item: React.FC<Props> = ({
  className,
  assignmentName,
  assignmentId,
  courseId,
  classColor,
  icon,
  timeLeft,
  timeWarning,
  workType,
  description, // Destructured description prop
}) => {
  const history = useHistory();

  const goToDashboard = useCallback(() => {
    history.push(`/googleclassroom-teacher-dashboard/class/${courseId}/assignment-summary/${assignmentId}`);
  }, [assignmentId, history, courseId]);

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  // Function to truncate description if too long
  const truncateDescription = (text: string | null | undefined, maxLength: number) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text || ''; // Return empty string if text is null or undefined
  };

  return (
    <S.Card style={{ marginTop: '8px', padding: '16px' }}>
      <Row align="middle">
        <Col span={3} style={{ textAlign: 'left' }}>
          <S.IconWrapper>{icon}</S.IconWrapper>
        </Col>
        <Col span={21}>
          <S.Title $align="left">{className}</S.Title>
        </Col>
        <Col span={24} style={{ textAlign: 'left', marginTop: '8px' }}>
          <Badge
            style={{ backgroundColor: '#E9A643', borderRadius: '40px', color: '#fff', fontWeight: 500, fontSize: '12px', padding: '8px 16px', textAlign: 'center',display: 'inline-block'  }}
            count={<div style={{ textAlign: 'center' }}>{capitalizeFirstLetter(workType)}</div>}
          />
        </Col>
        <Col span={24} style={{ marginTop: '8px' }}>
          <S.AssignmentName>
            <span
              style={{
                fontWeight: 500, // Bold for title appearance
                fontSize: '14px', // Slightly larger font size for title
                color: '#333', // Dark color for readability
                margin: '0', // No extra margin
                display: 'block', // Display as block for full width
                textAlign: 'left', // Align text to the left
                textTransform: 'capitalize', // Capitalize first letter of each word
              }}
            >
              {assignmentName}
            </span>
          </S.AssignmentName>
          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: description }} />}>
            <S.Description dangerouslySetInnerHTML={{ __html: truncateDescription(description, 50) }}></S.Description>
          </Tooltip>
        </Col>
        {timeLeft && (
          <Col span={24} style={{ marginTop: '6px' }}>
            <Row align="middle">
              <BsClock style={{ minWidth: 18, minHeight: 18, marginRight: 8 }} />
              <S.TimeLeft $fontSize="0.8em" $warning={timeWarning}>
                {Date.now() - timeLeft > 0 ? 'Finished' : formatDistance(timeLeft)}
              </S.TimeLeft>
            </Row>
          </Col>
        )}
      </Row>
      <Row style={{ marginTop: '0.5em' }} gutter={16} justify="center">
        <Col span={16} style={{ textAlign: 'center' }}>
          <S.DashboardButton
            text={'View Summary'}
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
